import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import Button from 'components/V2/Button';
import PropTypes from 'prop-types';

import classes from '../../../Guesty/components/ActivateGuesty/activateGuesty.module.css';

import { ReactComponent as LockIcon } from 'asset/IconsPack/at_lock-04-filled.svg';
import IntegrationLoader from 'asset/img/integration_loading.gif';

import HostFullyConnectionStatus from './hostfullyConnectionStatus';

const ActivateHostfully = ({
    clientId,
    isProcessing,
    isConnectingHostfully,
    isConnectionError,
    isExchangingToken,
    onClickActivateNow,
    integrationLink,
}) => {
    const { integrationName } = useParams();

    const renderContent = () => {
        const isHostfullyConnectionInProcess = isConnectingHostfully || isExchangingToken;

        switch (true) {
            case isProcessing:
                return (
                    <>
                        <img src={IntegrationLoader} alt="loading-animation" />
                        <Typography className={classes.description}>
                            Your activation is in process. Please wait!
                        </Typography>
                    </>
                );
            case isHostfullyConnectionInProcess:
                return (
                    <HostFullyConnectionStatus
                        clientId={clientId}
                        isConnectionError={isConnectionError}
                        isExchangingToken={isExchangingToken}
                    />
                );
            default:
                return (
                    <>
                        <LockIcon className={classes.lockIcon} />
                        <Typography className={classes.description}>
                            You need to activate to view the Integration details
                        </Typography>
                        <Button
                            onClick={onClickActivateNow}
                            intent="primary"
                            className={classes.primaryBtn}
                        >
                            Activate Now
                        </Button>
                        <a
                            href={integrationLink}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.guestyLink}
                        >
                            View in{' '}
                            {integrationName?.charAt(0).toUpperCase() + integrationName?.slice(1)}{' '}
                            Marketplace
                        </a>
                    </>
                );
        }
    };

    return (
        <Box className={classes.main}>
            <Box className={classes.container}>{renderContent()}</Box>
        </Box>
    );
};

ActivateHostfully.propTypes = {
    clientId: PropTypes.string.isRequired,
    isProcessing: PropTypes.bool,
    isConnectingHostfully: PropTypes.bool,
    isConnectionError: PropTypes.bool,
    isExchangingToken: PropTypes.bool,
    onClickActivateNow: PropTypes.func,
    integrationLink: PropTypes.string,
};

export default ActivateHostfully;
